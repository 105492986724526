import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { KitUtilLocale, KitUtilCommon, KitLocalePicker } from '@chargepoint/cp-toolkit';
import localStorage from './localStorage';
import utils from './common/utils';

const { getSupportedLocale, getRegionCodeForSSOSubdomain, getDefaultLocaleCodeForRegionCode } =
  KitUtilLocale;

// NOTES:
// Setup to fetch locally via public locales folder using HttpBackend and then cache in local storage.
// Subsequent loads will look in local storage cache first with a fallback to HttpBackend.

// Setting a smaller expiration time for non production which will force a fetch from HttpBackend and update local storage.
const localStorageExpirationTime =
  process.env.NODE_ENV !== 'production' ? 5 * 1000 : 7 * 24 * 60 * 60 * 1000; // hot reload or 7 day cache

let showDebugLogs = process.env.NODE_ENV !== 'production';
if (process.env.REACT_APP_DEBUG_I18N === 'false') {
  // Add an .env.local file with above flag to turn off debug logs.
  showDebugLogs = false;
}

const initializeRegionAndLocaleOverrides = (t) => {
  const subdomain = utils.getSubdomain();
  const regionCode = getRegionCodeForSSOSubdomain(t, subdomain);
  const defaultLocaleCode = getDefaultLocaleCodeForRegionCode(t, regionCode);

  // Locale is computed based on the following priority:
  // 1. "locale" query param
  // 2. window.localStorage value
  // 3. default locale based on current subdomains region

  let localeCode;
  const localeQueryParam = KitUtilCommon.getQueryParam(window.location.search, 'locale');
  if (localeQueryParam) {
    // Validate the passed in 'locale' query param for this NOSLET/subdomain.
    localeCode = localeQueryParam;
    const localePickerResult = KitLocalePicker.getKitLocalePickerResult(t, regionCode, localeCode);
    if (!localePickerResult) {
      // Invalid so use the default locale for this NOSLET/subdomain instead.
      localeCode = defaultLocaleCode;
    }
  } else {
    // Load the last saved locale from local storage
    localeCode = localStorage.getLocaleCode(defaultLocaleCode);
  }

  // At this point we should have a valid locale code based on the above rules.
  localStorage.setLocaleCode(localeCode);

  // IMPORTANT:
  // The localeCode needs to be converted to a supported locale that we have translations for.
  // The getSupportedLocale method is written based on the value stored in NOS aka `nosCookieLocale`.
  // localePickerResult.localeCode may be different than localePickerResult (i.e. 'es-US' !== 'es-MX')
  const localePickerResult = KitLocalePicker.getKitLocalePickerResult(t, regionCode, localeCode);
  if (!localePickerResult) {
    console.error(`Failed to load locale. [Region: ${regionCode}, Locale: ${localeCode}]`);
    return;
  }

  const supportedLocale = getSupportedLocale(localePickerResult.nosCookieLocale);
  if (i18n.language !== supportedLocale) {
    i18n.changeLanguage(supportedLocale);
  }
};

// IMPORTANT: This version needs to be manually incremented any time we have string updates.
// TODO: This will be fixed once we get proper CI/CD setup and can reference GITHUB_SHA.
const version = 2;

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      debug: showDebugLogs,
      load: 'currentOnly',
      ns: ['kit', 'translation'],
      fallbackLng: 'en-US',
      backend: {
        backends: [
          LocalStorageBackend, // primary
          HttpBackend, // fallback
        ],
        backendOptions: [
          {
            prefix: 'i18next_sso:', // prefix used to store languages in local storage
            expirationTime: localStorageExpirationTime,
            defaultVersion: `${version}`,
          },
          {
            // TODO: Fix this version query param to use GITHUB_SHA once we have proper CI/CD setup for SSO
            loadPath: `/locales/{{lng}}/{{ns}}.json?v=${version}`, // load path for HttpBackend fallback
          },
        ],
      },
    },
    (err, t) => {
      if (err) {
        console.error('Error initializing i18n', err);
      }

      initializeRegionAndLocaleOverrides(t);
    }
  );

i18n.on('initialized', () => {
  if (showDebugLogs) {
    console.info(
      'i18n initialized i18next.services.resourceStore.data',
      i18n.services.resourceStore.data
    );
  }
});

export default i18n;
