import * as Sentry from '@sentry/react';
import { KitUtilCommon } from '@chargepoint/cp-toolkit';
import FeatureFlagService from '../services/FeatureFlagService';

// Util functions

function getCommonRequestHeaders(i18n) {
  const acceptsLanguage = i18n.language ? i18n.language : '*';

  // Add Sentry transaction id header to allow for distributed tracing
  // https://sentry-docs-git-patch-1.sentry.dev/product/sentry-basics/guides/error-tracing/
  const transactionId = Math.random().toString(36).substring(2, 11);
  Sentry.configureScope((scope) => {
    scope.setTag('transaction_id', transactionId);
  });

  const requestHeaders = {
    'Content-Type': 'application/json',
    'Accept-Language': acceptsLanguage,
    'X-Transaction-ID': transactionId,
  };

  return requestHeaders;
}

function getDomain() {
  const urlParts = window.location.hostname.split('.');
  return urlParts
    .slice(0)
    .slice(-(urlParts.length === 4 ? 3 : 2))
    .join('.');
}

function getSubdomain() {
  let subdomain =
    window.location.hostname === 'localhost' ? 'sso' : window.location.host.split('.')[0];
  if (subdomain === 'sso-local') {
    subdomain = 'sso';
  }
  return subdomain;
}

function makeSsoApiUrl(endpoint) {
  return `/api${endpoint}`;
}

function getNosSubDomain(localePickerResult) {
  if (!localePickerResult) {
    return window.cp_constants.NOS_SUBDOMAIN;
  }

  switch (window.cp_constants.ENV) {
    case 'qa':
      return localePickerResult.nosSubdomain.qa || localePickerResult.nosSubdomain.default;
    case 'localdev':
      return localePickerResult.nosSubdomain.localdev || localePickerResult.nosSubdomain.default;
    default:
      return localePickerResult.nosSubdomain.default;
  }
}

// NOTES:
// The signup url does NOT accept a redirect query param... BUT we still need to pass along to sign up
// any of the query params that may have been on the redirect URL.
// Example:
// https://sso-local.ev-chargepoint.com/?redirect=https%3A%2F%2Fna.chargepoint.com%2Fconnections%2FZTJmZDc2NTVdDKuw40QpZuDZwx_rnPOXblAiN7Xp9mFl4y3hnvq0FLANA8AE4AbeobZOFaObU9U%253D%3FpartnerToken%3DWP170379SDG%26connection%3D30521%26requiredInstance%3Dus
// https://signup.ev-chargepoint.com/signup/profile?partnerToken=WP170379SDG&connection=30521&requiredInstance=us
// Please reach out to Brandon or Peter if you need help understanding this.

function makeSignUpUrl(localePickerResult) {
  const signupSubdomainEnabled = FeatureFlagService.getInstance().isEnabled(
    FeatureFlagService.signupSubdomainEnabled
  );

  let signupUrl = signupSubdomainEnabled
    ? `https://signup.${getDomain()}`
    : `https://${getNosSubDomain(localePickerResult)}.${window.cp_constants.ENV_DOMAIN}`;
  signupUrl += '/signup/profile';

  const queryParamsObj = KitUtilCommon.getQueryObject(window.location.search);
  if (localePickerResult && queryParamsObj) {
    // Pull out any query params that might be in the redirect URL
    const { hasOwnProperty } = Object.prototype;
    if (hasOwnProperty.call(queryParamsObj, 'redirect')) {
      const redirectUrlString = KitUtilCommon.getQueryParam(window.location.search, 'redirect');
      try {
        const redirectUrl = new URL(redirectUrlString);
        const redirectUrlQueryParamsObj = KitUtilCommon.getQueryObject(redirectUrl.search);
        Object.assign(queryParamsObj, redirectUrlQueryParamsObj);
      } catch (e) {
        if (e instanceof TypeError) {
          console.error('Invalid redirect URL', redirectUrlString);
        } else {
          throw e;
        }
      }
    }

    // Always pass the current locale
    queryParamsObj.locale = localePickerResult.localeCode;

    // Append all the query params to signup url string
    const updatedParams = KitUtilCommon.getQueryString(queryParamsObj);
    signupUrl += updatedParams;
  }

  return signupUrl;
}

function makePrivacyPolicyUrl(localePickerResult) {
  return `https://${getNosSubDomain(localePickerResult)}.${
    window.cp_constants.ENV_DOMAIN
  }/privacy_policy?instance=${localePickerResult.regionCode}&locale=${
    localePickerResult.localeCode
  }`;
}

function makeCaliforniaPrivacyUrl(localePickerResult) {
  return `https://${getNosSubDomain(localePickerResult)}.${window.cp_constants.ENV_DOMAIN}${
    localePickerResult.californiaPrivacyPath
  }`;
}

function validateEmail(email) {
  const re = /^.+@.+$/;
  return re.test(email);
}

export default {
  getCommonRequestHeaders,
  getDomain,
  getSubdomain,
  makeSsoApiUrl,
  makeSignUpUrl,
  makePrivacyPolicyUrl,
  makeCaliforniaPrivacyUrl,
  validateEmail,
};
